import Panel from './Panel.jsx'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useEffect, useRef} from 'react'

export default function BoxF0427(props) {

    gsap.registerPlugin(ScrollTrigger);

    const gap = 4;
    const sg = 20;
    const holes = [200, 20];

    const boxWrapper = useRef();
    const bottomPanel = useRef();
    const backPanel = useRef();
    const frontPanel = useRef();
    const frontFlapLeft = useRef();
    const frontFlapRight = useRef();
    const backFlapLeft = useRef();
    const backFlapRight = useRef();

    const topPanel = useRef();
    const frontOverlapPanel = useRef();
    const leftSideOuterPanel = useRef();
    const rightSideOuterPanel = useRef();
    const leftSideFoldPanel = useRef();
    const rightSideFoldPanel = useRef();
    const leftFrontOverlapFlap = useRef();
    const rightFrontOverlapFlap = useRef();
    const leftSideInnerPanel = useRef();
    const rightSideInnerPanel = useRef();
    const topFlapLeft = useRef();
    const topFlapRight = useRef();

    useEffect(() => {

        ScrollTrigger.refresh();
        gsap.globalTimeline.clear();

        const tl = gsap
            .timeline({
                defaults: {
                    duration: 1,
                    ease: "power1.inOut",
                },
                scrollTrigger: {
                    trigger: ".scrollable-space",
                    start: "top top",
                    end: "bottom bottom",
                    scrub: 1,
                    markers: true
                }
            })

        tl
            .to(frontPanel.current.rotation, {
                x: -.5 * Math.PI,
            })
            .to(frontFlapLeft.current.rotation, {
                y: -.5 * Math.PI,
            }, 1)
            .to(frontFlapRight.current.rotation, {
                y: .5 * Math.PI,
            }, 1)
            .to(boxWrapper.current.position, {
                y: -.5 * props.size[2],
                z: -.5 * (props.size[0] + props.size[2])
            }, 2)
            .to(backPanel.current.rotation, {
                x: -.5 * Math.PI,
            }, 2)
            .to(backFlapLeft.current.rotation, {
                y: .5 * Math.PI,
            }, 3)
            .to(backFlapRight.current.rotation, {
                y: -.5 * Math.PI,
            }, 3)

            .to([leftSideOuterPanel.current.rotation, rightSideOuterPanel.current.rotation], {
                x: 0,
            }, 4)
            .to([leftSideFoldPanel.current.rotation, rightSideFoldPanel.current.rotation], {
                y: .5 * Math.PI,
            }, 5)
            .to(leftSideInnerPanel.current.rotation, {
                y: .5 * Math.PI,
            }, 6)
            .to(rightSideInnerPanel.current.rotation, {
                y: .5 * Math.PI,
            }, 5.3)

            .to(topFlapLeft.current.rotation, {
                y: .51 * Math.PI,
            }, 7)
            .to(topFlapRight.current.rotation, {
                y: .51 * Math.PI,
            }, 7)

            .to(topPanel.current.rotation, {
                x: -.5 * Math.PI,
            }, 8)

            .to(leftFrontOverlapFlap.current.rotation, {
                y: -.51 * Math.PI,
            }, 7.8)
            .to(rightFrontOverlapFlap.current.rotation, {
                y: .51 * Math.PI,
            }, 7.8)

            .to(frontOverlapPanel.current.rotation, {
                x: -.49 * Math.PI,
            }, 9.5)

    }, [props.size[0], props.size[2]]);


    return <>
        <group ref={boxWrapper}>
            <group rotation={[.5 * Math.PI, 0, .5 * Math.PI]} position={[.5 * props.size[1], 0, -.5 * props.size[0]]}>
                <group ref={bottomPanel}>
                    <Panel shapeName="427_rect_w_holes" holes={holes} sg={sg} size={[props.size[0], props.size[1]]} colors={props.colors}/>
                </group>
            </group>

            <group rotation={[.5 * Math.PI, 0, 0]} position={[-.5 * props.size[1] + .5 * sg, 0, .5 * props.size[0]]}>
                <group ref={frontPanel}>
                    <Panel size={[props.size[1] - sg, props.size[2]]} colors={props.colors}/>

                    <group position-y={gap}>
                        <group ref={frontFlapLeft}>
                            <Panel shapeName="427_side_trapeze" sg={sg} shapeFlip={true} size={[.5 * props.size[0], props.size[2] - 2 * gap]} colors={props.colors}/>
                        </group>
                    </group>
                    <group position={[props.size[1] - sg, gap, 0]}>
                        <group ref={frontFlapRight}>
                            <Panel shapeName="427_side_trapeze" sg={sg} size={[.5 * props.size[0], props.size[2] - 2 * gap]} colors={props.colors}/>
                        </group>
                    </group>

                </group>
            </group>

            <group rotation={[-.5 * Math.PI, Math.PI, 0]} position={[.5 * props.size[1] - .25 * sg, 0, -.5 * props.size[0]]}>
                <group ref={backPanel}>
                    <Panel size={[props.size[1] - .5 * sg, props.size[2]]} colors={props.colors}/>

                    <group position-y={gap}>
                        <group ref={backFlapRight}>
                            <Panel shapeName="427_side_trapeze" sg={sg} shapeFlip={true} size={[.5 * props.size[0], props.size[2] - 2 * gap]} colors={props.colors}/>
                        </group>
                    </group>
                    <group position={[props.size[1] - .5 * sg, gap, 0]}>
                        <group ref={backFlapLeft}>
                            <Panel shapeName="427_side_trapeze" sg={sg} size={[.5 * props.size[0], props.size[2] - 2 * gap]} colors={props.colors}/>
                        </group>
                    </group>

                    <group position={[.75 * sg, props.size[2], 0]}>
                        <group ref={topPanel}>
                            <Panel size={[props.size[1] - 2 * sg, props.size[0]]} colors={props.colors}/>

                            <group position={[-.75 * sg, props.size[0], 0]}>
                                <group ref={frontOverlapPanel}>
                                    <Panel size={[props.size[1] - .5 * sg, props.size[2]]} colors={props.colors}/>

                                    <group position={[props.size[1] - .5 * sg, gap, 0]}>
                                        <group ref={rightFrontOverlapFlap}>
                                            <Panel shapeName="roundedTriangle" size={[props.size[2] - sg, props.size[2] - gap]} colors={props.colors}/>
                                        </group>
                                    </group>

                                    <group position-y={gap}>
                                        <group ref={leftFrontOverlapFlap}>
                                            <Panel shapeName="roundedTriangle" shapeFlip={true} size={[props.size[2] - sg, props.size[2] - gap]} colors={props.colors}/>
                                        </group>
                                    </group>

                                </group>
                            </group>

                            <group position-x={props.size[1] - 2 * sg}>
                                <group ref={topFlapLeft}>
                                    <Panel shapeName="trapeze" trapOffset={Math.atan(Math.PI / 12) * props.size[2]} size={[props.size[2], props.size[0]]} colors={props.colors}/>
                                </group>
                            </group>

                            <group rotation-z={Math.PI} position-y={props.size[0]}>
                                <group ref={topFlapRight}>
                                    <Panel shapeName="trapeze" trapOffset={Math.atan(Math.PI / 12) * props.size[2]} size={[props.size[2], props.size[0]]} colors={props.colors}/>
                                </group>
                            </group>

                        </group>
                    </group>

                </group>
            </group>

            <group rotation={[0, -.5 * Math.PI, 0]} position={[-.5 * props.size[1], 0, -.5 * props.size[0]]}>
                <group ref={leftSideOuterPanel} rotation-x={.5 * Math.PI}>
                    <Panel size={[props.size[0], props.size[2]]} colors={props.colors}/>

                    <group rotation={[0, -.5 * Math.PI, .5 * Math.PI]} position={[props.size[0], props.size[2], 0]}>
                        <group ref={leftSideFoldPanel} rotation-x={.5 * Math.PI}>
                            <Panel shapeName="trapeze" trapOffset={.5 * sg} size={[sg, props.size[0]]} colors={props.colors}/>

                            <group position={[sg, .5 * sg, 0]}>
                                <group ref={leftSideInnerPanel}>
                                    <Panel shapeName="427_rect_w_two_legs" holes={holes} size={[props.size[2], props.size[0] - sg]} colors={props.colors}/>
                                </group>
                            </group>
                        </group>
                    </group>

                </group>
            </group>

            <group rotation={[0, .5 * Math.PI, 0]} position={[.5 * props.size[1], 0, .5 * props.size[0]]}>
                <group ref={rightSideOuterPanel} rotation-x={.5 * Math.PI}>
                    <Panel size={[props.size[0], props.size[2]]} colors={props.colors}/>

                    <group rotation={[0, -.5 * Math.PI, .5 * Math.PI]} position={[props.size[0], props.size[2], 0]}>
                        <group ref={rightSideFoldPanel} rotation-x={.5 * Math.PI}>
                            <Panel shapeName="trapeze" trapOffset={.5 * sg} size={[sg, props.size[0]]} colors={props.colors}/>

                            <group position={[sg, .5 * sg, 0]}>
                                <group ref={rightSideInnerPanel}>
                                    <Panel shapeName="427_rect_w_two_legs" holes={holes} size={[props.size[2], props.size[0] - sg]} colors={props.colors}/>
                                </group>
                            </group>
                        </group>
                    </group>

                </group>
            </group>
        </group>
    </>
}
