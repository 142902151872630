import './style.css'
import ReactDOM from 'react-dom/client'
import {Canvas} from '@react-three/fiber'
import Experience from './Experience.jsx'
import {Leva} from 'leva'


function App() {
    return (
        <>
            <div id="canvas-container">
                <Canvas
                    dpr={[1, 2]}
                    camera={{
                        fov: 45,
                        near: 1,
                        far: 500,
                        position: [140, 280, 100]
                    }}
                >
                    <Experience/>
                </Canvas>
            </div>

            <Leva/>

            <div className="scrollable-space"></div>
        </>
    )
}

ReactDOM.createRoot(document.getElementById('root')).render(<App/>)