import * as THREE from "three";
import {Line} from '@react-three/drei'
import {Line2} from "three/examples/jsm/lines/Line2"

export default function Panel(props) {

    const getShape = (shapeName) => {
        const shape = new THREE.Shape();
        
        const flip = (props.shapeFlip ? -1 : 1);
        const overlapOffset_216 = 30;

        if (shapeName === "rect") {
            
            shape.moveTo(0, 0);
            shape.lineTo(props.size[0], 0);
            shape.lineTo(props.size[0], props.size[1]);
            shape.lineTo(0, props.size[1]);
            shape.lineTo(0, 0);
            
        } else if (shapeName === "trapeze") {
            
            props.size[0] *= flip;

            shape.moveTo(0, 0);
            shape.lineTo(props.size[0], props.trapOffset);
            shape.lineTo(props.size[0], props.size[1] - props.trapOffset);
            shape.lineTo(0, props.size[1]);
            shape.lineTo(0, 0);
            
        } else if (shapeName === "427_side_trapeze") {

            const sG_427 = props.sg ? props.sg : 0;

            props.size[0] *= flip;
            props.size[1] -= 1;
            const offset = 2 * sG_427 * flip;

            shape.moveTo(0, 0);
            shape.lineTo(props.size[0] - offset, 0);
            shape.lineTo(props.size[0] - offset, .5 * sG_427);
            shape.lineTo(props.size[0], sG_427);
            shape.lineTo(props.size[0], props.size[1]);
            shape.lineTo(0, props.size[1]);
            shape.lineTo(0, 0);
            
        } else if (shapeName === "roundedTriangle") {

            props.size[0] *= flip;
            shape.moveTo(0, 0);
            shape.lineTo(props.size[0], 0);
            shape.quadraticCurveTo(props.size[0], props.size[1], 0, props.size[1]);
            shape.lineTo(0, 0);
            
        } else if (shapeName === "427_rect_w_holes") {

            const sG_427 = props.sg ? props.sg : 0;
            const holes_427 = props.holes.length ? props.holes : [];

            shape.moveTo(0, 0);
            shape.lineTo(props.size[0], 0);
            shape.lineTo(props.size[0], props.size[1]);
            shape.lineTo(0, props.size[1]);

            for (let i = 0; i < 4; i++) {
                const holeStartX = i < 2 ? (.25 * props.size[0] - .5 * holes_427[0]) : (.75 * props.size[0] - .5 * holes_427[0]);
                const holeStartY = i % 2 ? sG_427 : (props.size[1] - holes_427[1] - sG_427);
                const holePath = new THREE.Path()
                    .moveTo(holeStartX, holeStartY)
                    .lineTo(holeStartX, holeStartY + holes_427[1])
                    .lineTo(holeStartX + holes_427[0], holeStartY + holes_427[1])
                    .lineTo(holeStartX + holes_427[0], holeStartY)

                shape.holes.push(holePath);
            }
            
        } else if (shapeName === "427_rect_w_two_legs") {

            const holes_427 = props.holes.length ? props.holes : [];

            shape.moveTo(0, 0);
            shape.lineTo(props.size[0], 0);
            for (let i = 0; i < 2; i++) {
                const start = (i ? .75 : .25) * props.size[1] - .5 * holes_427[0];
                shape.lineTo(props.size[0], start)
                shape.lineTo(props.size[0] + holes_427[1], start)
                shape.lineTo(props.size[0] + holes_427[1], start + holes_427[0])
                shape.lineTo(props.size[0], start + holes_427[0])
            }
            shape.lineTo(props.size[0], props.size[1]);
            shape.lineTo(0, props.size[1]);

        } else if (shapeName === "216_back_flap") {

            const midWidth = props.size[0] - 2 * props.size[1] + 2 * overlapOffset_216;
            shape.moveTo(0, 0);
            shape.lineTo(props.size[0], 0);
            shape.lineTo(props.size[0], props.size[1] + overlapOffset_216);
            shape.lineTo(props.size[0] - .5 * (props.size[0] - midWidth), props.size[1] + overlapOffset_216);
            shape.lineTo(props.size[0] - .5 * (props.size[0] - midWidth), props.size[1]);
            shape.lineTo(.5 * (props.size[0] - midWidth), props.size[1]);
            shape.lineTo(.5 * (props.size[0] - midWidth), props.size[1] + overlapOffset_216);
            shape.lineTo(0, props.size[1] + overlapOffset_216);
            shape.lineTo(0, 0);

        } else if (shapeName === "216_front_flap" || shapeName === "216_front_flap_cut") {

            const midWidth = props.size[0] - 2 * props.size[1] + 2 * overlapOffset_216;
            shape.moveTo(0, 0);
            shape.lineTo(props.size[0], 0);
            shape.lineTo(props.size[0] - .5 * (props.size[0] - midWidth), props.size[1]);
            shape.lineTo(props.size[0] - .5 * (props.size[0] - midWidth), props.size[1] + (shapeName === "216_front_flap_cut" ? 0 : overlapOffset_216));
            shape.lineTo(.5 * (props.size[0] - midWidth), props.size[1] + (shapeName === "216_front_flap_cut" ? 0 : overlapOffset_216));
            shape.lineTo(.5 * (props.size[0] - midWidth), props.size[1]);
            shape.lineTo(0, 0);
            
        } else if (shapeName === "216_side_flap") {

            shape.moveTo(0, 0);
            if (flip === -1) {
                shape.lineTo(props.size[0], 0);
                shape.lineTo(.5 * props.size[0], props.size[1] - overlapOffset_216);
                shape.lineTo(.5 * props.size[0], props.size[1]);
                shape.lineTo(0, props.size[1]);
            } else {
                shape.lineTo(props.size[0], 0);
                shape.lineTo(props.size[0], props.size[1]);
                shape.lineTo(.5 * props.size[0], props.size[1]);
                shape.lineTo(.5 * props.size[0], props.size[1] - overlapOffset_216);
            }
            shape.lineTo(0, 0);
            
        }

            return [shape.getPoints(30), new THREE.ShapeGeometry(shape)];
    };

    const shape = getShape(props.shapeName || "rect");

    return <>
        <mesh geometry={shape[1]} {...props}>
            <meshStandardMaterial color={props.colors[1]} side={THREE.BackSide}/>
            <Line position={[0, 0, .5]} points={shape[0]} color={props.colors[2]} linewidth={1} >
            </Line>
        </mesh>
        <mesh geometry={shape[1]} {...props}>
            <meshStandardMaterial color={props.colors[0]}/>
            <Line position={[0, 0, -.5]} points={shape[0]} color={props.colors[2]} linewidth={1}>
            </Line>
        </mesh>
    </>
}
