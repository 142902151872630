import Panel from './Panel.jsx'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useEffect, useRef} from 'react'

export default function BoxF0200(props) {

    gsap.registerPlugin(ScrollTrigger);

    const gap = 2;

    const flapHeight = .5 * (props.size[0] - gap);
    const frontPanel = useRef();
    const backPanel = useRef();
    const rightSideOuterPanel = useRef();
    const leftSideOuterPanel = useRef();
    const leftBottomFlap = useRef();
    const rightBottomFlap = useRef();
    const frontBottomFlap = useRef();
    const backBottomFlap = useRef();

    useEffect(() => {

        ScrollTrigger.refresh();
        gsap.globalTimeline.clear();

        gsap
            .timeline({
                defaults: {
                    duration: 1,
                    ease: "power1.inOut",
                },
                scrollTrigger: {
                    trigger: ".scrollable-space",
                    start: "top top",
                    end: "bottom bottom",
                    scrub: 1,
                    markers: true
                }
            })


            .to(frontPanel.current.position, {
                x: .5 * props.size[0],
                z: .5 * props.size[0]
            }, 0)
            .to(backPanel.current.rotation, {
                y: -.49 * Math.PI,
            }, 0)
            .to(leftSideOuterPanel.current.rotation, {
                y: .49 * Math.PI,
            }, 0)
            .to(rightSideOuterPanel.current.rotation, {
                y: .49 * Math.PI,
            }, 0)

            .to(leftBottomFlap.current.rotation, {
                x: -.51 * Math.PI,
                ease: "back.in(3)",
            }, .7)
            .to(rightBottomFlap.current.rotation, {
                x: -.51 * Math.PI,
                ease: "back.in(3)",
            }, .7)

            .to(backBottomFlap.current.rotation, {
                x: -.497 * Math.PI,
                ease: "back.in(3)",
            }, 1)
            .to(frontBottomFlap.current.rotation, {
                x: -.497 * Math.PI,
                ease: "back.in(3)",
            }, 1)

    }, [props.size[0]]);


    return <>
        <group>
            <group ref={frontPanel}>
                <Panel size={[props.size[1], props.size[2]]} colors={props.colors}/>

                <group position-x={props.size[1]} rotation-y={.01 * Math.PI}>
                    <group ref={rightSideOuterPanel}>
                        <Panel size={[props.size[0], props.size[2]]} colors={props.colors}/>

                        <group position-x={props.size[0]} rotation-y={.99 * Math.PI}>
                            <group ref={backPanel}>
                                <Panel size={[props.size[1], props.size[2]]} colors={props.colors}/>

                                <group position-x={props.size[1]} rotation-y={.01 * Math.PI}>
                                    <group ref={leftSideOuterPanel}>
                                        <Panel size={[props.size[0], props.size[2]]} colors={props.colors}/>

                                        <group rotation-x={Math.PI} rotation-y={Math.PI} position-x={props.size[0] - .5 * gap}>
                                            <group ref={leftBottomFlap}>
                                                <Panel size={[props.size[0] - gap, flapHeight]} colors={props.colors}/>
                                            </group>
                                        </group>

                                    </group>
                                </group>

                                <group rotation-x={Math.PI} rotation-y={Math.PI} position-x={props.size[1] - .5 * gap}>
                                    <group ref={backBottomFlap}>
                                        <Panel size={[props.size[1] - gap, .5 * props.size[0]]} colors={props.colors}/>
                                    </group>
                                </group>
                            </group>
                        </group>

                        <group rotation-x={Math.PI} rotation-y={Math.PI} position-x={props.size[0] - .5 * gap}>
                            <group ref={rightBottomFlap}>
                                <Panel size={[props.size[0] - gap, flapHeight]} colors={props.colors}/>
                            </group>
                        </group>
                    </group>
                </group>

                <group rotation-x={Math.PI} rotation-y={Math.PI} position-x={props.size[1] - .5 * gap}>
                    <group ref={frontBottomFlap}>
                        <Panel size={[props.size[1] - gap, .5 * props.size[0]]} colors={props.colors}/>
                    </group>
                </group>
            </group>
        </group>
    </>
}
