import Panel from './Panel.jsx'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useEffect, useRef} from 'react'

export default function BoxF0409(props) {

    gsap.registerPlugin(ScrollTrigger);

    const gap = 4;

    const boxWrapper = useRef();
    const bottomPanel = useRef();
    const backPanel = useRef();
    const frontPanel = useRef();
    const frontFlapLeft = useRef();
    const frontFlapRight = useRef();
    const backFlapLeft = useRef();
    const backFlapRight = useRef();

    const topPanel = useRef();
    const frontOverlapPanel = useRef();
    const frontOverlapFlapLeft = useRef();
    const frontOverlapFlapRight = useRef();
    const leftSideOuterPanel = useRef();
    const rightSideOuterPanel = useRef();
    const topFlapLeft = useRef();
    const topFlapRight = useRef();

    useEffect(() => {

        ScrollTrigger.refresh();
        gsap.globalTimeline.clear();

        const tl = gsap
            .timeline({
                defaults: {
                    duration: 1,
                    ease: "power1.inOut",
                },
                scrollTrigger: {
                    trigger: ".scrollable-space",
                    start: "top top",
                    end: "bottom bottom",
                    scrub: 1,
                    markers: true
                }
            })

            .to(frontPanel.current.rotation, {
                x: -.5 * Math.PI,
            })
            .to([frontFlapLeft.current.rotation, frontFlapRight.current.rotation], {
                y: .51 * Math.PI,
            }, 1)
            .to(boxWrapper.current.position, {
                y: -.5 * props.size[2],
                z: -.5 * (props.size[0] + props.size[2])
            }, 1.5)
            .to(backPanel.current.rotation, {
                x: -.5 * Math.PI,
            }, 1.5)
            .to([backFlapLeft.current.rotation, backFlapRight.current.rotation], {
                y: .51 * Math.PI,
            }, 2)
            .to([leftSideOuterPanel.current.rotation, rightSideOuterPanel.current.rotation], {
                x: 0,
            }, 2.6)

            .to(topPanel.current.rotation, {
                x: -.5 * Math.PI,
            }, 3.3)
            .to(frontOverlapPanel.current.rotation, {
                x: -.495 * Math.PI,
            }, 3.3)

            .to([frontOverlapFlapLeft.current.rotation, frontOverlapFlapRight.current.rotation], {
                y: .51 * Math.PI,
            }, 3.6)
            .to([topFlapLeft.current.rotation, topFlapRight.current.rotation], {
                y: .495 * Math.PI,
            }, 4.6)


    }, [props.size[0], props.size[2]]);


    return <>
        <group ref={boxWrapper}>
            <group rotation={[.5 * Math.PI, 0, .5 * Math.PI]} position={[.5 * props.size[1], 0, -.5 * props.size[0]]}>
                <group ref={bottomPanel}>
                    <Panel size={[props.size[0], props.size[1]]} colors={props.colors}/>
                </group>
            </group>

            <group rotation={[.5 * Math.PI, 0, 0]} position={[-.5 * props.size[1], 0, .5 * props.size[0]]}>
                <group ref={frontPanel}>
                    <Panel size={[props.size[1], props.size[2]]} colors={props.colors}/>

                    <group rotation={[0, 0, Math.PI]} position={[0, props.size[2] - gap, 0]}>
                        <group ref={frontFlapLeft}>
                            <Panel size={[props.size[2], props.size[2] - 2 * gap]} colors={props.colors}/>
                        </group>
                    </group>
                    <group position={[props.size[1], gap, 0]}>
                        <group ref={frontFlapRight}>
                            <Panel size={[props.size[2], props.size[2] - 2 * gap]} colors={props.colors}/>
                        </group>
                    </group>

                </group>
            </group>

            <group rotation={[-.5 * Math.PI, Math.PI, 0]} position={[.5 * props.size[1], 0, -.5 * props.size[0]]}>
                <group ref={backPanel}>
                    <Panel size={[props.size[1], props.size[2]]} colors={props.colors}/>

                    <group rotation={[0, 0, Math.PI]} position={[0, props.size[2] - gap, 0]}>
                        <group ref={backFlapRight}>
                            <Panel size={[props.size[2], props.size[2] - 2 * gap]} colors={props.colors}/>
                        </group>
                    </group>
                    <group position={[props.size[1], gap, 0]}>
                        <group ref={backFlapLeft}>
                            <Panel size={[props.size[2], props.size[2] - 2 * gap]} colors={props.colors}/>
                        </group>
                    </group>


                    <group position={[0, props.size[2], 0]}>
                        <group ref={topPanel}>
                            <Panel size={[props.size[1], props.size[0]]} colors={props.colors}/>

                            <group position={[0, props.size[0], 0]}>
                                <group ref={frontOverlapPanel}>
                                    <Panel size={[props.size[1], props.size[2]]} colors={props.colors}/>

                                    <group rotation={[0, 0, Math.PI]} position={[0, props.size[2] - gap, 0]}>
                                        <group ref={frontOverlapFlapLeft}>
                                            <Panel size={[props.size[2], props.size[2] - 2 * gap]} colors={props.colors}/>
                                        </group>
                                    </group>
                                    <group position={[props.size[1], gap, 0]}>
                                        <group ref={frontOverlapFlapRight}>
                                            <Panel size={[props.size[2], props.size[2] - 2 * gap]} colors={props.colors}/>
                                        </group>
                                    </group>
                                </group>
                            </group>

                            <group position-x={props.size[1]}>
                                <group ref={topFlapLeft}>
                                    <Panel size={[props.size[2], props.size[0]]} colors={props.colors}/>
                                </group>
                            </group>

                            <group rotation-z={Math.PI} position-y={props.size[0]}>
                                <group ref={topFlapRight}>
                                    <Panel size={[props.size[2], props.size[0]]} colors={props.colors}/>
                                </group>
                            </group>

                        </group>
                    </group>

                </group>
            </group>

            <group rotation={[0, -.5 * Math.PI, 0]} position={[-.5 * props.size[1], 0, -.5 * props.size[0]]}>
                <group ref={leftSideOuterPanel} rotation-x={.5 * Math.PI}>
                    <Panel size={[props.size[0], props.size[2]]} colors={props.colors}/>
                </group>
            </group>

            <group rotation={[0, .5 * Math.PI, 0]} position={[.5 * props.size[1], 0, .5 * props.size[0]]}>
                <group ref={rightSideOuterPanel} rotation-x={.5 * Math.PI}>
                    <Panel size={[props.size[0], props.size[2]]} colors={props.colors}/>
                </group>
            </group>
        </group>
    </>
}
