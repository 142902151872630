import Panel from './Panel.jsx'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useEffect, useRef} from 'react'

export default function BoxF0203(props) {

    gsap.registerPlugin(ScrollTrigger);

    const gap = 2;

    const flapHeight = props.size[0] - gap;

    const frontPanel = useRef();
    const backPanel = useRef();
    const rightSideOuterPanel = useRef();
    const leftSideOuterPanel = useRef();
    const leftBottomFlap = useRef();
    const rightBottomFlap = useRef();
    const frontBottomFlap = useRef();
    const backBottomFlap = useRef();

    const leftTopFlap = useRef();
    const rightTopFlap = useRef();
    const frontTopFlap = useRef();
    const backTopFlap = useRef();

    useEffect(() => {

        ScrollTrigger.refresh();
        gsap.globalTimeline.clear();

        const tl = gsap
            .timeline({
                defaults: {
                    duration: 1,
                    ease: "power1.inOut",
                },
                scrollTrigger: {
                    trigger: ".scrollable-space",
                    start: "top top",
                    end: "bottom bottom",
                    scrub: 1,
                    markers: true
                }
            })

            .to(frontPanel.current.position, {
                x: .5 * props.size[0],
                z: .5 * props.size[0]
            }, 0)
            .to(backPanel.current.rotation, {
                y: -.49 * Math.PI,
            }, 0)
            .to(leftSideOuterPanel.current.rotation, {
                y: .49 * Math.PI,
            }, 0)
            .to(rightSideOuterPanel.current.rotation, {
                y: .49 * Math.PI,
            }, 0)

            .to(leftBottomFlap.current.rotation, {
                x: -.5 * Math.PI,
            }, .7)
            .to(leftBottomFlap.current.position, {
                duration: .5,
                y: -2 * gap
            }, .7)
            .to(rightBottomFlap.current.rotation, {
                x: -.5 * Math.PI,
                ease: "back.in(3)",
            }, .8)
            .to(rightBottomFlap.current.position, {
                duration: .5,
                y: -1.5 * gap
            }, .8)
            .to(backBottomFlap.current.rotation, {
                duration: .75,
                x: -.5 * Math.PI,
                ease: "back.in(3)",
            }, 1.4)
            .to(backBottomFlap.current.position, {
                duration: .5,
                y: -.5 * gap,
            }, 1.4)
            .to(frontBottomFlap.current.rotation, {
                x: -.498 * Math.PI,
                ease: "back.in(2)",
            }, 1.6)

            .to(leftTopFlap.current.rotation, {
                x: -.5 * Math.PI,
            }, 2.3)
            .to(leftTopFlap.current.position, {
                duration: .5,
                y: -2 * gap
            }, 2.3)
            .to(rightTopFlap.current.rotation, {
                x: -.5 * Math.PI,
                ease: "back.in(3)",
            }, 2.4)
            .to(rightTopFlap.current.position, {
                duration: .5,
                y: -1.5 * gap
            }, 2.4)
            .to(backTopFlap.current.rotation, {
                duration: .75,
                x: -.5 * Math.PI,
                ease: "back.in(3)",
            }, 3)
            .to(backTopFlap.current.position, {
                duration: .5,
                y: -.5 * gap,
            }, 3)
            .to(frontTopFlap.current.rotation, {
                x: -.498 * Math.PI,
                ease: "back.in(2)",
            }, 3.2)

    }, [props.size[0]]);


    return <>
        <group>
            <group ref={frontPanel}>
                <Panel size={[props.size[1], props.size[2]]} colors={props.colors}/>

                <group position-x={props.size[1]} rotation-y={.01 * Math.PI}>
                    <group ref={rightSideOuterPanel}>
                        <Panel size={[props.size[0], props.size[2]]} colors={props.colors}/>

                        <group position-x={props.size[0]} rotation-y={.99 * Math.PI}>
                            <group ref={backPanel}>
                                <Panel size={[props.size[1], props.size[2]]} colors={props.colors}/>

                                <group position-x={props.size[1]} rotation-y={.01 * Math.PI}>
                                    <group ref={leftSideOuterPanel}>
                                        <Panel size={[props.size[0], props.size[2]]} colors={props.colors}/>

                                        <group rotation-x={Math.PI} rotation-y={Math.PI} position-x={props.size[0] - .5 * gap}>
                                            <group ref={leftBottomFlap}>
                                                <Panel size={[props.size[0] - gap, flapHeight]} colors={props.colors}/>
                                            </group>
                                        </group>
                                        <group position-x={.5 * gap} position-y={props.size[2]}>
                                            <group ref={leftTopFlap}>
                                                <Panel size={[props.size[0] - gap, flapHeight]} colors={props.colors}/>
                                            </group>
                                        </group>

                                    </group>
                                </group>

                                <group rotation-x={Math.PI} rotation-y={Math.PI} position-x={props.size[1] - .5 * gap}>
                                    <group ref={backBottomFlap}>
                                        <Panel size={[props.size[1] - gap, flapHeight]} colors={props.colors}/>
                                    </group>
                                </group>

                                <group position-x={.5 * gap} position-y={props.size[2]}>
                                    <group ref={backTopFlap}>
                                        <Panel size={[props.size[1] - gap, flapHeight]} colors={props.colors}/>
                                    </group>
                                </group>
                            </group>
                        </group>

                        <group rotation-x={Math.PI} rotation-y={Math.PI} position-x={props.size[0] - .5 * gap}>
                            <group ref={rightBottomFlap}>
                                <Panel size={[props.size[0] - gap, flapHeight]} colors={props.colors}/>
                            </group>
                        </group>

                        <group position-x={.5 * gap} position-y={props.size[2]}>
                            <group ref={rightTopFlap}>
                                <Panel size={[props.size[0] - gap, flapHeight]} colors={props.colors}/>
                            </group>
                        </group>
                    </group>
                </group>

                <group rotation-x={Math.PI} rotation-y={Math.PI} position-x={props.size[1] - .5 * gap}>
                    <group ref={frontBottomFlap}>
                        <Panel size={[props.size[1] - gap, flapHeight]} colors={props.colors}/>
                    </group>
                </group>

                <group position-x={.5 * gap} position-y={props.size[2]}>
                    <group ref={frontTopFlap}>
                        <Panel size={[props.size[1] - gap, flapHeight]} colors={props.colors}/>
                    </group>
                </group>
            </group>
        </group>
    </>
}
