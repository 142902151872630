import Panel from './Panel.jsx'
import {useRef} from 'react'

export default function BoxF0110(props) {

    const foldBottom = useRef();

    return <>
        <group rotation-x={-.5 * Math.PI}>
            <group ref={foldBottom}>
                <Panel size={props.size} colors={props.colors}/>
            </group>
        </group>
    </>
}
