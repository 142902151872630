import {Bounds, useBounds, OrbitControls} from '@react-three/drei'
import {useFrame} from '@react-three/fiber'
import {useControls} from 'leva'
import {useEffect, useRef} from 'react'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import BoxF0110 from "./boxes/BoxF0110.jsx";
import BoxF0200 from "./boxes/BoxF0200.jsx";
import BoxF0201 from "./boxes/BoxF0201.jsx";
import BoxF0202 from "./boxes/BoxF0202.jsx";
import BoxF0203 from "./boxes/BoxF0203.jsx";
import BoxF0216 from "./boxes/BoxF0216.jsx";
import BoxF0409 from "./boxes/BoxF0409.jsx";
import BoxF0427 from "./boxes/BoxF0427.jsx";

export default function Experience() {

    const light = useRef();

    useFrame((state) => {
        light.current.quaternion.copy(state.camera.quaternion);
    });

    const {lineColor, colorFront, colorBack, boxName, w, l, h} = useControls('', {
        colorFront: '#C3AA83',
        colorBack: '#E7E9E1',
        lineColor: '#807664',
        w:
            {
                min: 1,
                max: 2400,
                value: 1000,
                step: 1
            },
        l:
            {
                min: 1,
                max: 2400,
                value: 1500,
                step: 1
            },
        h:
            {
                min: 1,
                max: 1000,
                value: 400,
                step: 1
            },
        boxName: {
            options: ["F0110", "F0200", "F0201", "F0202", "F0203", "F0216", "F0409", "F0427"],
            value: "F0409"
        }
    }, [])


    useEffect(() => {
        ScrollTrigger.refresh();
        gsap.globalTimeline.clear();
        window.scrollTo(0, 0);
    }, [boxName, w, l, h])


    function AutoFit() {
        const bounds = useBounds()
        useEffect(() => {
            ScrollTrigger.refresh();
            gsap.globalTimeline.clear();
            window.scrollTo(0, 0);
            bounds.refresh().clip().fit();
        }, [])
    }

    let boxComponent;
    if (boxName === "F0110") {
        boxComponent = <BoxF0110 size={[w, l]} colors={[colorFront, colorBack, lineColor]}/>
    } else if (boxName === "F0200") {
        boxComponent = <BoxF0200 size={[w, l, h]} colors={[colorFront, colorBack, lineColor]}/>
    } else if (boxName === "F0201") {
        boxComponent = <BoxF0201 size={[w, l, h]} colors={[colorFront, colorBack, lineColor]}/>
    } else if (boxName === "F0202") {
        boxComponent = <BoxF0202 size={[w, l, h]} colors={[colorFront, colorBack, lineColor]}/>
    } else if (boxName === "F0203") {
        boxComponent = <BoxF0203 size={[w, l, h]} colors={[colorFront, colorBack, lineColor]}/>
    } else if (boxName === "F0216") {
        boxComponent = <BoxF0216 size={[w, l, h]} colors={[colorFront, colorBack, lineColor]}/>
    } else if (boxName === "F0409") {
        boxComponent = <BoxF0409 size={[w, l, h]} colors={[colorFront, colorBack, lineColor]}/>
    } else if (boxName === "F0427") {
        boxComponent = <BoxF0427 size={[w, l, h]} colors={[colorFront, colorBack, lineColor]}/>
    }

    return <>

        <OrbitControls enableZoom={false} autoRotate={false} enablePan={false} autoRotateSpeed={.3} makeDefault/>

        <group ref={light}>
            <directionalLight position={[0, 0, 3]} intensity={3}/>
        </group>
        <ambientLight intensity={1}/>

        <Bounds fit clip observe margin={1.2} maxDuration={.3}>
            <AutoFit/>
            <group>
                {boxComponent}
            </group>
        </Bounds>

    </>
}